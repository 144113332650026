import { insurance_payment_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { useMutation, useQueryClient } from 'react-query';
import { insuranceCustomerKeys } from '../cache-keys/insuranceCustomerKeys';

export function useCreateInsuranceCardMandate() {
  const api = useAPI();
  const client = useQueryClient();

  return useMutation(insurance_payment_api.createCardMandate(api), {
    onSuccess: (data) => {
      const cacheKey = insuranceCustomerKeys.root({
        insuranceID: data.InsuranceID,
        customerID: data.CustomerID,
        marketCountry: data.MarketCountry,
      });
      client.invalidateQueries(cacheKey);
    },
  });
}
